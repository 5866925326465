/**
 * This is the frontend code that is responsible for syncing the localSession data with the parent window.
 * It subscribes to the auth_channel BroadcastChannel and pushes messages to the parent window.
 */
function SyncFrame(): JSX.Element {
    // Subscribe to the auth_channel BroadcastChannel
    const bc = new BroadcastChannel('auth_channel');
    const messageChannel = new MessageChannel();
    window.parent.postMessage({channel: 'auth_channel'}, '*', [messageChannel.port2]);


    bc.onmessage = (event) => {
        // Post a message to the parent window
        messageChannel.port1.postMessage(event.data);
    };
    
    return (
        <div>
        <h1>Sync Frame</h1>
        </div>
    );
}

export default SyncFrame;