export function addIdentity(workspaceName: string = 'workspace1') {
    let stringifiedData = localStorage.getItem('auth') || '{}';
    let data = JSON.parse(stringifiedData);
    const accountData = data['test@skiff.earth'];
    if (!accountData) {
      data['test@skiff.earth'] = {
        workspaces: [
          workspaceName
        ]
      }
    } else if (!accountData.workspaces) {
      accountData.workspaces = [
        workspaceName
      ]
    } else {
      accountData.workspaces.push(workspaceName);
    }
    const stringyData = JSON.stringify(data);
    localStorage.setItem('auth', stringyData);
    const bc = new BroadcastChannel('auth_channel');
    bc.postMessage(stringyData);
}