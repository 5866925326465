import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { addIdentity } from './identity';

function App() {
  const [authText, setAuthText] = useState('');
  const [authenticatedStatus, setAuthenticatedStatus] = useState(false)

  async function testAuthentication() {
    const response = await fetch('https://auth.skiff.earth/api/isAuthenticated', {
      method: 'GET',
      credentials: 'include',
    });
  
    if (response.status === 200) {
      setAuthText('Authenticated!');
      setAuthenticatedStatus(true);
      // continue with auth
      const queryParameters = new URLSearchParams(window.location.search);
      const redirect_uri = queryParameters.get('redirect_uri')
      if (redirect_uri) {
        const requestURL = new URL('https://auth.skiff.earth/api/requestToken');
        requestURL.searchParams.set('redirect_uri', redirect_uri);
        const response2 = await fetch(requestURL, {
          method: 'GET',
          credentials: 'include',
        });

        if (response2.redirected && redirect_uri) { // we logged in to pages.skiff.earth
          window.location.href = redirect_uri;
        }
      }
    } else {
      setAuthText('You are not authenticated!');
      setAuthenticatedStatus(false);
    }
  }

  async function logout(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const response = await fetch('https://auth.skiff.earth/api/logout', {
      method: 'POST',
      credentials: 'include',
    });
  
    if (response.status === 200) {
      setAuthText('You are not authenticated!');
      setAuthenticatedStatus(false);
    } else {
      setAuthText('Unable to logout!');
    }
  }

  function addWorkspace(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    addIdentity(new Date().toISOString());
  }

  useEffect(() => {
    testAuthentication();
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    //Prevent page reload
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const queryParameters = new URLSearchParams(window.location.search);
    const redirect_uri = queryParameters.get('redirect_uri')
    if (redirect_uri) {
      formData.append('redirect_uri', redirect_uri);
    }
    
    const response = await fetch('https://auth.skiff.earth/api/login', {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data', <- do not set this
      },
      body: formData,
      credentials: 'include',
      mode: 'cors'
    });

    setAuthText('Authenticated!');
    setAuthenticatedStatus(true);
    addIdentity();
    
    if (response.redirected && redirect_uri) { // we logged in to pages.skiff.earth
      window.location.href = redirect_uri;
    }
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p id='auth-text'>{authText}</p>
        {!authenticatedStatus &&
          <form onSubmit={handleSubmit}>
             <input type='email' name='email' required/>
             <input type='password' name='password'required />
             <div className='button-container'>
               <input type='submit' />
             </div>
          </form>   
        }
        {authenticatedStatus && 
          <button onClick={logout}>Log out</button>
        }

        {
          authenticatedStatus &&
          <button onClick={addWorkspace}>Add Workspace</button>
        }
      </header>
    </div>
  );
}

export default App;
